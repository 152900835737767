 // !! DO NOT EDIT THIS FILE DIRECTLY !!
 // !! It will be overrideen by gulp !!
 //    Please use global/scripts.js

"use strict";

/**
* @file cookie.js
* Generic cookie handling functions.
* @author Hal Burgiss 2009-03-22
*/
function setCookie(name, value, days) {
     if (days) {
          var date = new Date();
          date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
          var expires = "; expires=" + date.toGMTString();
     } else var expires = "";
     document.cookie = name + "=" + value + expires + "; path=/";
}
function readCookie(name) {
     var nameEQ = name + "=";
     var ca = document.cookie.split(';');
     for (var i = 0; i < ca.length; i++) {
          var c = ca[i];
          while (c.charAt(0) == ' ') {
               c = c.substring(1, c.length);
          }if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
     }
     return null;
}
function eraseCookie(name) {
     setCookie(name, "", -1);
}
 // !! DO NOT EDIT THIS FILE DIRECTLY !!
 // !! It will be overrideen by gulp !!
 //    Please use global/scripts.js

'use strict';

/**
 * Slate Mobile Navigation Menu
 *
 */
(function () {

	'use strict';

	/**
  * 'is_mobile' variable to determine if this is a mobile size.
  */

	var set_is_mobile,
	    is_mobile = false;

	window.addEventListener('load', function () {
		var menuToggle = document.getElementById('menu-toggle');

		(set_is_mobile = function set_is_mobile() {
			if (window.getComputedStyle(menuToggle).display === "none") {
				is_mobile = false;
			} else {
				is_mobile = true;
			}
		})();

		/**
   * Toggle menu open/closed when clicking menu toggle
   */
		menuToggle.addEventListener('click', function () {
			this.classList.toggle('open');
			document.getElementById('menu').classList.toggle('open');
			document.querySelector('body').classList.toggle('opened-menu');

			if (this.classList.contains('open')) {
				this.setAttribute('aria-expanded', true);
			} else {
				this.setAttribute('aria-expanded', false);
			}
		});
	});

	var subToggles = document.querySelectorAll('.submenu-toggle');

	for (var i = 0; subToggles.length > i; i++) {
		var focus_out_timer = null;
		var toggle_button = subToggles[i];

		toggle_button.addEventListener('click', function () {
			this.classList.toggle('open');
			this.parentNode.classList.toggle('open');

			if (this.classList.contains('open')) {
				this.setAttribute('aria-expanded', true);
			} else {
				this.setAttribute('aria-expanded', false);
			}
		});
	}

	var mainMenuItems = document.querySelectorAll('.main-menu>ul>li');

	for (var i = 0; mainMenuItems.length > i; i++) {
		var item = mainMenuItems[i];

		if (item.querySelector('button')) {
			item.addEventListener('mouseover', function () {
				this.classList.add('open');
			});

			item.addEventListener('mouseout', function () {
				if (this.classList.contains('open')) {
					this.classList.remove('open');
				}
			});
		}
	}
})();
 // !! DO NOT EDIT THIS FILE DIRECTLY !!
 // !! It will be overrideen by gulp !!
 //    Please use global/scripts.js

'use strict';

/**
* This file is loaded asyncronously in the footer.
* All custom global scripts should be included here.
*/

/*
A little info for this js file:
	As much as possible, this file should be kept organized. Currently, two major divisions are seen below, delinitated by their respective functions of "on load" and "ready".
	As mentioned below, most code added to this file should be included within the "on load" function.
	For ease of future use, include any new code at the bottom of the relevant section of the page, adding clear comments to explain the purpose of the code being written,
	including any relavant details regarding what the code overrides, etc.
*/

(function () {
	'use strict';

	// rerun lazyload on screen size updates

	window.addEventListener('resize', function () {
		dbs.lazyload();
	});

	// Load event function ... most custom stuff goes here. Safer for async.
	window.addEventListener('load', function () {
		/* Search Form functionality
   * The click functionality involves three different actions,
   * depending on conditions the various clicks provide.
   */
		var searchOpen = document.querySelectorAll('.search-form__button--open');
		var searchClose = document.querySelectorAll('.search-form__button--close');

		if (searchOpen) {
			for (var i = 0; searchOpen.length > i; i++) {
				searchOpen[i].addEventListener('click', function () {
					this.parentElement.classList.add('search-form--active');
					this.parentElement.querySelector('.site_search__field').focus();

					// Hide the search bar after click out of it
					document.getElementById('content').addEventListener('click', function () {
						if (this.parentElement.classList.contains('search-form--active')) {
							this.parentElement.classList.remove('search-form--active');
						}
					});
				});
			}

			for (var i = 0; searchClose.length > i; i++) {
				searchClose[i].addEventListener('click', function () {
					this.parentElement.classList.remove('search-form--active');
				});
			}
		}

		/**
   * Linkclicker
   * 
   * A class placed on a wrapping element with a link inside to make the wrapper clickable.
   * Note: the container should have only one link.
   */
		var linkclickers = document.querySelectorAll('.linkclicker');

		for (var i = 0; linkclickers.length > i; i++) {
			var linkclicker = linkclickers[i];

			linkclicker.addEventListener('click', function (e) {
				e.preventDefault();
				var link = this.querySelector('a');
				var linkPath = link.getAttribute('href');

				if (link.getAttribute('target', "_blank")) {
					window.open(linkPath);
				} else {
					window.location.href = linkPath;
				}
			});
		}

		/**
   * Force external links/PDFs to open in a new tab.
   * From https://stackoverflow.com/questions/2910946/test-if-links-are-external-with-jquery-javascript
   * 2019-07-11 - TA
   */
		function link_is_external(link_element) {
			return link_element.host !== window.location.host || link_element.href.indexOf("pdf") > -1;
		}

		var anchorElements = document.getElementsByTagName('a');
		var _iteratorNormalCompletion = true;
		var _didIteratorError = false;
		var _iteratorError = undefined;

		try {
			for (var _iterator = anchorElements[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
				var anchorElement = _step.value;


				if (link_is_external(anchorElement)) {
					anchorElement.setAttribute('target', '_blank');
					anchorElement.setAttribute('rel', 'noopener');

					// Add assistive text to alert of external links
					var assistiveText = '<span class="assistive">. External Link. Opens in new window.</span>';
					var assistiveTextContainer = document.createElement('span');
					assistiveTextContainer.innerHTML = assistiveText;
					anchorElement.appendChild(assistiveTextContainer.firstChild);
				}
			}

			/**
    * Detects if element is in viewport
    * 
    * @usage
    * var el = document.querySelector('.element-class');
    *
    * window.addEventListener('scroll', function(event) {
    * 		if (isInViewport(el)) {
    *			el.classList.add("in-view");
    *		}
    * }, false);
    * 
    * NOTE: If the whole layout is taller than the viewport, this will never fire. It is often
    * better to target one small element inside a layout.
    */
		} catch (err) {
			_didIteratorError = true;
			_iteratorError = err;
		} finally {
			try {
				if (!_iteratorNormalCompletion && _iterator.return) {
					_iterator.return();
				}
			} finally {
				if (_didIteratorError) {
					throw _iteratorError;
				}
			}
		}

		function isInViewport(elem) {
			var distance = elem.getBoundingClientRect();
			return distance.top >= 0 && distance.left >= 0 && distance.bottom <= (window.innerHeight || document.documentElement.clientHeight) && distance.right <= (window.innerWidth || document.documentElement.clientWidth);
		};

		/**
   * ANOTHER NOTE: Right now the idea is to use this as needed on certain layouts, rather 
   * than querying all elements of a certain class globally and looping through them. 
   * However, that is a reasonable approach if there is a significant amount of 
   * scroll animations being done; especially if it's already on the home page. Here
   * is an example of how that would be done.
   */
		var els = document.querySelectorAll('.animated');

		var _loop = function _loop(_i) {
			var _this = els[_i];
			window.addEventListener('scroll', function (event) {
				if (isInViewport(_this)) {
					_this.classList.add("in-view");
				}
			}, false);
		};

		for (var _i = 0; els.length > _i; _i++) {
			_loop(_i);
		}

		/**
   * Collapsibles
   */
		var collapsibleHeaders = document.querySelectorAll('.collapsible-row__header');

		if (collapsibleHeaders) {
			for (var i = 0; collapsibleHeaders.length > i; i++) {
				var collapsibleHeader = collapsibleHeaders[i];

				collapsibleHeader.addEventListener('click', function () {
					this.classList.toggle('active');
					var textHeight = this.nextElementSibling.querySelector('.collapsible-row__content').clientHeight; // Get height of text within collapsible row
					var rowHeight = this.nextElementSibling.clientHeight; // Get height of row to see if it's expanded

					if (rowHeight == 0) {
						// If collapsible content is collapsed...
						this.nextElementSibling.style.maxHeight = textHeight + 1 + "px";
					} else {
						this.nextElementSibling.style.maxHeight = 0 + "px";
					}

					if (this.parentNode.classList.contains('expanded')) {
						this.setAttribute('aria-expanded', 'true');
					} else {
						this.setAttribute('aria-expanded', 'false');
					}
				});
			}
		}

		/**
   * If a submenu expands past the right side of the viewport, this
   * moves it left until it is all the way inside the viewport.
   */
		var mainMenuToplevelItems = document.querySelectorAll('.main-menu .menu__item.toplevel');
		for (var i = 0; i < mainMenuToplevelItems.length; i++) {
			mainMenuToplevelItems[i].addEventListener('mouseenter', function () {
				var submenu = this.querySelector('.menu__submenu');
				if (submenu !== null) {
					var bounding = submenu.getBoundingClientRect();
					if (bounding.right > (window.innerWidth || document.documentElement.clientWidth)) {
						var diff;
						if (window.innerWidth < document.documentElement.clientWidth) {
							diff = bounding.right - window.innerWidth;
						} else {
							diff = bounding.right - document.documentElement.clientWidth;
						}
						var submenuStyle = window.getComputedStyle(submenu);
						var rightValue = submenuStyle.getPropertyValue('left').replace('px', '');
						submenu.style.left = Number(rightValue) - diff + 'px';
					}
				}
			});
		}

		/**
   * Cookie Popup scripts
   */
		var popUp = document.getElementById('cookie-popup');
		var popUpMain = document.getElementById('popup-main');

		// Hide popup and background filter and set cookie
		function accept() {
			popUp.style.display = "none";
			document.body.classList.add('cookies');
			setCookie("accepts-cookie", "accepts-cookie-set", 365);
		}

		document.getElementById('accept-button').addEventListener('click', function () {
			accept();
		});

		var cookie = readCookie('accepts-cookie');
		// If user already has accepted cookies, don't show popup
		if (cookie == 'accepts-cookie-set') {
			popUp.style.display = "none";
		} else {
			// Force screen reader to read popup
			popUpMain.setAttribute("aria-live", "assertive");
		}
	}); // End of load function


	// Include ready events here
	window.addEventListener('DOMContentLoaded', function () {});
})();